import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Img from 'gatsby-image';
import { rhythm } from '../utils/typography';
import projectBackgroundWhite from './project-background-white.svg';

interface Props {
  image: string;
  title: string;
  role?: string;
  description: string;
  github?: string;
  skills?: string;
  tags: string[];
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: ${rhythm(1)};
  background-color: white;
  padding: ${rhythm(2)};
  border-radius: 4px; 
  box-shadow: 0 50px 100px rgba(50, 50, 93, 0.05),
      0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.1); 
  overflow: hidden;
`;

const Role = styled.span`
  font-size: 1em;
`;

const ProjectImage = styled(Img)`
  position: absolute !important;
  top: 0;
  left: 0;
  object-fit: cover;
  min-width: 100%;
  min-height: 100%;
  width: ${rhythm(4)};
  height: auto; 
`;

const ProjectBackground = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100%;
  width: 83%;
  background: url(${projectBackgroundWhite});
  background-size: cover; 
`;

const ProjectTitle = styled.h3`
  margin-top: 0;
  margin-bottom: ${rhythm(1 / 4)};
  font-size: 2.0em;
`;

const ProjectContent = styled.div`
  z-index: 2;
  width: 60%;
`;

const ProjectFooter = styled.div`
  z-index: 2;
`;

const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const LinkContainer = styled.div`
  margin-top: ${rhythm(1)};
`;

const ProjectDescription = styled.p`
  margin-top: ${rhythm(2)};
  margin-bottom: ${rhythm(1)};
`;

const IconLink = styled.a`
  margin-right: ${rhythm(1 / 4)};
  margin-top: ${rhythm(1 / 4)};
  padding: ${rhythm(1 / 2)};
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11),
            0 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 4px; 
  box-sizing: border-box; 
  text-decoration: none; 
  transition: all 0.15s ease; 
`;

const ProjectTag = styled.span`
  border-radius: 4px;
  border: solid 1px #42A5F5; 
  padding: ${rhythm(1 / 4)} ${rhythm(1 / 2)};
  margin-right: ${rhythm(0.5)};
  margin-top: ${rhythm(0.5)};
  font-size: 0.8em; 
`;

const LinkIcon = styled(FontAwesomeIcon)`
  margin-right: ${rhythm(0.5)};
`;


interface PreviewLinkProps {
  preview: string;
}

interface GithubLinkProps {
  github: string;
}

interface ProjectTagProps {
  tags: string[];
}

interface TagProp {
  tag: string;
}

const ProjectTags: React.FC<ProjectTagProps> = ({ tags }: ProjectTagProps) => (
  <TagContainer>
    {tags.map((tag) => <ProjectTag key={tag}>{tag}</ProjectTag>)}
  </TagContainer>
);

const PrviewLink: React.FC<PreviewLinkProps> = ({ preview }: PreviewLinkProps) => (
  <a href={preview} rel="noopener noreferrer" target="_blank" aria-label="preview">
    <LinkIcon
      icon="external-link-alt"
    />
  </a>
);

const GithubLink: React.FC<GithubLinkProps> = ({ github }: GithubLinkProps) => (
  <IconLink href={github} rel="noopener noreferrer" target="_blank" aria-label="github">
    <LinkIcon
      icon={['fab', 'github']}
    />
    View Source
  </IconLink>
);

const ProjectCard: React.FC<Props> = ({
  image, title, role, description, github, tags,
}: Props) => (
  <Container>
    <ProjectImage fixed={image} />
    <ProjectBackground />
    <ProjectContent>
      <ProjectTitle>
        {title}
      </ProjectTitle>
      {role && <Role>{role}</Role>}
      <ProjectDescription
        /* eslint-disable-next-line react/no-danger */
        dangerouslySetInnerHTML={{
          __html: description,
        }}
      />
      <ProjectFooter>
        <ProjectTags tags={tags} />
        <LinkContainer>
          {github && <GithubLink github={github} />}
        </LinkContainer>
      </ProjectFooter>
    </ProjectContent>
  </Container>
);

export default ProjectCard;
