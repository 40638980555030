import React from 'react';
import { Link, graphql } from 'gatsby';

import { Element } from 'react-scroll/modules';
import Hero from '../components/hero';
import Layout from '../components/layout';
import SEO from '../components/seo';
import ProjectCard from '../components/project_card';
import Navbar from '../components/navbar';
import '../fontawesome';
import { rhythm } from '../utils/typography';

interface Props {
  data: {
    allMarkdownRemark: {
      edges: {
        node: {
          excerpt: string;
          fields: {
            slug: string;
          };
          frontmatter: {
            title: string;
            image: {
              childImageSharp: {
                fixed: string;
              };
            };
            role: string;
            description: string;
            github: string;
            tags: string[];
          };
        };
      }[];
    };
    site: {
      siteMetadata: {
        title: string;
      };
    };
  };
}

const PageIndex: React.FC<Props> = ({ data }: Props) => {
  const siteTitle = data.site.siteMetadata.title;
  const projects = data.allMarkdownRemark.edges;

  return (
    <Layout title="">
      <SEO title="Oswin So" />
      <Navbar />
      <Hero />
      <Element name="projects" />
      {projects.map(({ node }) => {
        const title = node.frontmatter.title || node.fields.slug;
        const {
          image, role, description, github, tags,
        } = node.frontmatter;
        return (
          <ProjectCard
            image={image.childImageSharp.fixed}
            description={description}
            title={title}
            role={role}
            github={github}
            key={title}
            tags={tags}
          />
        );
      })}
      <Element name="contact" />
    </Layout>
  );
};

export default PageIndex;

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
        allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
            edges {
                node {
                    excerpt
                    fields {
                        slug
                    }
                    frontmatter {
                        date(formatString: "MMMM DD, YYYY")
                        title
                        role
                        github
                        description
                        tags
                        image {
                            childImageSharp {
                                fixed(width: 500) {
                                    ...GatsbyImageSharpFixed
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
