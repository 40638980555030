import React from 'react';
import styled from 'styled-components';
import { rhythm } from '../utils/typography';

import CartpoleAnimation from './cartpoleAnimation';

const HeroContainer = styled.div`
  margin: 20vh auto 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const HeroDescription = styled.div`
  margin: ${rhythm(1)} ${0};
  text-align: center;
  font-size: 1.5em;
  line-height: 2.0em;
`;

const Hero: React.FC = () => (
  <HeroContainer>
    <CartpoleAnimation />
    <HeroDescription>
      I am a CS major at Georgia Tech. My interests are in motion planning and control.
      <br />
      Check out my projects / experiences below:
    </HeroDescription>
  </HeroContainer>
);

export default Hero;
