import React, { Component, createRef } from 'react';
import styled from 'styled-components';

interface CartpoleProps {
  x: number;
  theta: number;
}

const CartpoleContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const CartpoleCanvas = styled.canvas`
  
`;

class Cartpole extends Component<CartpoleProps, {}> {
  private canvasRef = createRef<HTMLCanvasElement>();

  componentDidUpdate(): void {
    const { x, theta } = this.props;
    const canvas = this.canvasRef.current;

    if (!canvas) {
      return;
    }

    const ctx = canvas.getContext('2d')!;
    const { width } = canvas;
    const { height } = canvas;
    const cartWidth = 100;
    const cartHeight = 50;
    const poleWidth = 10;
    const poleHeight = 200;
    ctx.save();
    ctx.beginPath();
    ctx.clearRect(0, 0, width, height);

    ctx.translate(width / 2 + x, height / 2);
    // Cart
    ctx.fillStyle = '#4397AC';
    ctx.fillRect(-cartWidth / 2, -cartHeight / 2, cartWidth, cartHeight);

    // Pole
    ctx.rotate((theta * Math.PI) / 180);
    ctx.fillStyle = '#0d37ac';
    ctx.fillRect(-poleWidth / 2, 0, poleWidth, poleHeight);
    ctx.restore();
  }

  render(): JSX.Element {
    return (
      <CartpoleContainer>
        <CartpoleCanvas ref={this.canvasRef} width={800} height={400} />
      </CartpoleContainer>
    );
  }
}

export default Cartpole;
