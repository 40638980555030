import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-scroll';
import { rhythm } from '../utils/typography';

const NavbarContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0 auto;
`;

const Nav = styled.nav`
  display: flex;
  justify-content: space-around;
  width: ${rhythm(10)};
  font-size: 1.5em;
`;

const NavLink = styled(Link)`
  box-shadow: none;
  text-decoration: none; 
  position: relative; 
  cursor: pointer;
  
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: dodgerblue;
    visibility: hidden;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: all 0.2s ease-in-out 0s;
    transition: all 0.2s ease-in-out 0s; 
  }
  
  &:hover:before {
    visibility: visible;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
 
  
  &:hover {
    color: dodgerblue;
    &:after {
      width: 100%;
    } 
  }
`;

const Navbar: React.FC = () => (
  <NavbarContainer>
    <Nav>
      <NavLink to="projects" smooth>Projects</NavLink>
      <NavLink to="contact" smooth>Contact</NavLink>
    </Nav>
  </NavbarContainer>
);

export default Navbar;
