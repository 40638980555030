import React, { Component } from 'react';
import Cartpole from './cartpole';

type CartpoleAnimationState = {
  x: number;
  xdot: number;
  theta: number;
  thetadot: number;
  index: number;
};

class CartpoleAnimation extends React.Component<{}, CartpoleAnimationState> {
  private rAF = 0;

  constructor() {
    super({});
    this.state = { x: 0, xdot: 0, theta: 0, thetadot: 0, index: 0 };
    this.updateAnimationState = this.updateAnimationState.bind(this);
  }

  componentDidMount(): void {
    this.rAF = requestAnimationFrame(this.updateAnimationState);
  }

  componentWillUnmount(): void {
    cancelAnimationFrame(this.rAF);
  }

  updateAnimationState(): void {
    this.setState((prevState) => ({
      x: 100 * Math.sin(prevState.index), theta: prevState.theta + 1, index: prevState.index + 0.1,
    }));
    this.rAF = requestAnimationFrame(this.updateAnimationState);
  }

  render(): JSX.Element {
    const { x, theta } = this.state;
    return <Cartpole x={x} theta={theta} />;
  }
}

export default CartpoleAnimation;
